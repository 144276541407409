import axios from 'axios'

export const state = {
    user: {
        email: localStorage.getItem('user_email') || '',
        token: localStorage.getItem('access_token') || null,
        refreshToken: localStorage.getItem('refresh_token') || null,
        adminRole: localStorage.getItem('admin_role') || null
    }
}

export const getters = {
    isAuthenticated: state => !!state.user.email,
    getTokens: state => {
        return {
            token: state.user.token,
            refreshToken: state.user.refreshToken,
        }
    }
}

export const mutations = {
    SET_USER(state, user){
        state.user = user
    },
    SET_EMAIL(state, email){
        state.user.email = email
    },
    SET_ADMIN(state, role){
        state.user.adminRole = role
    },
    REFRESH_TOKENS(state, tokens) {
        state.user = {
            ...state.user,
            token: tokens.access_token,
            refreshToken: tokens.refresh_token
        }
    },
    LOG_OUT(state){
        state.user = {
            email: '',
            token: null,
            refreshToken: null
        }
    },

}

export const actions = {
    async LogIn({commit}, User) {
        const uninterceptedAxiosInstance = axios.create();
        await uninterceptedAxiosInstance.post('auth/login/ldap', User).then(response => {
            const token = response.data.data.access_token
            const refreshToken = response.data.data.refresh_token
            localStorage.setItem('access_token', token)
            localStorage.setItem('refresh_token', refreshToken)
            let userData = {
                email: '',
                token: token,
                refreshToken: refreshToken,
                adminRole: null
            }
            commit('SET_USER', userData)
            return userData
        })
        .then(async (userData) => {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + userData.token
            }
            await axios.get('users/me', { headers }).then((response) => {
                if (response.data.data.email) {
                    let email = response.data.data.email
                    localStorage.setItem('user_email', email)
                    commit('SET_EMAIL', email)
                    let userRoleId = response.data.data.role
                    if ([VUE_APP_ADMIN_ROLE_ID, VUE_APP_GLOBAL_EDITOR_ROLE_ID].includes(userRoleId)) {
                        localStorage.setItem('admin_role', 'global')
                        commit('SET_ADMIN', 'global')
                    }
                    if (userRoleId == VUE_APP_EDITOR_ROLE_ID) {
                        localStorage.setItem('admin_role', 'editor')
                        commit('SET_ADMIN', 'editor')
                    }
                }
            });
        })
        .catch(error => {
            throw error;
        });
    },
    refreshTokens({commit}, Tokens) {
        localStorage.setItem('access_token', Tokens.access_token)
        localStorage.setItem('refresh_token', Tokens.refresh_token)
        commit('REFRESH_TOKENS', Tokens)
    },
    async LogOut({commit}) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('user_email')
        localStorage.removeItem('admin_role')
        commit('LOG_OUT')
    }
}